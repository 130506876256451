import {useEffect, useState} from "react";

import {addListenerSafe, removeListenerSafe} from "../utils/mediaQueries";
import {noOp} from "../utils/noOp";

export enum Breakpoint {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  "2XL" = 1536,
}

/**
 * Mobile first breakpoint listener matching tailwind default breakpoints
 */
export const useTwMediaQuery = (bp: Breakpoint): boolean => {
  const dimension = "width";
  const queryString = `(min-${dimension}: ${bp}px)`;

  const [matches, setMatches] = useState(false);
  useEffect(() => {
    if (typeof matchMedia !== "undefined") {
      const mediaQueryList = matchMedia(queryString);
      setMatches(mediaQueryList.matches);
      const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
      addListenerSafe(mediaQueryList, listener);
      return () => {
        removeListenerSafe(mediaQueryList, listener);
      };
    }
    return noOp;
  }, [queryString]);

  return matches;
};
