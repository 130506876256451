import {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react";

export type Disclosure = {
  set: Dispatch<SetStateAction<boolean>>;
  close: () => void;
  open: () => void;
  toggle: () => void;
  isOpen: boolean;
  isClosed: boolean;
};

export const useDisclosure = (initialValue = false): Disclosure => {
  const [state, setState] = useState(initialValue);

  const close = useCallback(() => setState(false), []);
  const open = useCallback(() => setState(true), []);
  const toggle = useCallback(() => setState(state => !state), []);

  return useMemo(
    () => ({
      close,
      open,
      toggle,
      set: setState,
      isOpen: state,
      isClosed: !state,
    }),
    [close, open, toggle, state, setState],
  );
};
