import {useTranslation} from "ni18n";
import React, {useMemo} from "react";
import {I18nNamespace} from "src/i18n-namespaces";

import PhoneNumInput from "./PhoneNumInput";
import {RegionSelection} from "./RegionSelection";
import {getRegionData} from "./utils/getRegionData";

type Props = {
  phoneNumber: string;
  handlePhoneChange: (input: string) => void;
  setValidationPN?: (validate: boolean) => void;
  phoneErrorMsg?: string;
  region: string;
  handleRegionChange: (input: string) => void;
  validator?: (input: string, slug: string) => string | undefined;
  triggerValidationOnSubmit?: boolean;
  onBlur?: () => void;
  onEnter?: (input?: string) => unknown;
  "data-cy"?: string;
};

const fallbackRegion = {
  name: "United States",
  slug: "US",
  callingCode: "1",
} as const;

const PhoneInput = ({
  phoneNumber,
  handlePhoneChange,
  setValidationPN,
  onEnter,
  phoneErrorMsg,
  region,
  handleRegionChange,
  onBlur,
  ...props
}: Props) => {
  const {t} = useTranslation(I18nNamespace.WEB_SCHEDULING);

  const regionData = useMemo(() => getRegionData(), []);

  const currentRegionData = useMemo(
    () => regionData.find(r => r.slug === region),
    [region, regionData],
  );

  return (
    <>
      <div className="w-2/5 sm:w-3/12 pr-2 pb-4">
        <RegionSelection
          ariaLabel={"patient-region-select"}
          region={region}
          regionData={regionData}
          handleRegionChange={handleRegionChange}
          label={t("Region", {ns: "web-scheduling"})}
        />
      </div>
      <div className="w-3/5 sm:w-6/12 sm:pr-2">
        <PhoneNumInput
          ariaLabel={"patient-phone-number"}
          handleChange={handlePhoneChange}
          phoneNumber={phoneNumber}
          errorMsg={phoneErrorMsg}
          setValidation={setValidationPN}
          region={currentRegionData || fallbackRegion}
          label={t("Phone Number", {ns: "web-scheduling"})}
          isRequired
          onBlur={onBlur}
          onEnter={onEnter}
          data-cy={props["data-cy"]}
        />
      </div>
    </>
  );
};

export default React.memo(PhoneInput);
