import memoizee from "memoizee";

import {ButtonStatus} from "./constants";
import {ButtonShape, ButtonSize, ButtonVariant} from ".";

const styles = {
  focus: "outline-hidden outline-4 outline-offset-0 outline-brightBlue focus-visible:outline",
  base: "border border-solid box-border transition-all flex justify-center items-center",
  baseText: "whitespace-nowrap text-center font-i",
  baseInteraction: "cursor-pointer disabled:cursor-default disabled:opacity-70",
} as const;

const shapeStyle: Record<ButtonShape, string> = {
  round: "rounded-4xl",
  circle: "rounded-full p-2 shrink-0 grow-0 leading-none flex items-center justify-center",
};

/**
 * Note: 'hover:' and 'disabled:hover:' are required because 'a' tags do not match 'enabled:'
 * Latest buttons guide: https://www.figma.com/file/PKqE0iYfxXHC6zZ0Rzq5Hq/Buttons?node-id=0%3A1
 */
const variantStyle: Record<ButtonVariant, string> = {
  destroy:
    "bg-error-800 border-error-800 text-white hover:text-white hover:bg-error-600 hover:border-error-600 disabled:hover:bg-error-800 disabled:hover:border-error-800",
  ghost:
    "border-gray100 bg-gray800/10 text-gray800 hover:text-gray800 hover:bg-gray800/20 disabled:hover:bg-gray800/10 hover:border-gray50 disabled:hover:border-gray100",
  green:
    "border-darkGreen bg-darkGreen text-white hover:text-white hover:bg-green disabled:hover:bg-darkGreen hover:border-green disabled:hover:border-darkGreen",
  outline:
    "bg-transparent border-gray800 text-gray800 hover:bg-gray800 hover:text-white disabled:hover:bg-transparent disabled:hover:text-gray800",
  fill: "border-gray800 bg-gray800 text-white disabled:hover:text-white hover:bg-white hover:text-gray800 disabled:hover:bg-gray800",
  white:
    "border-white bg-white text-gray800 hover:bg-gray800 hover:border-gray800 hover:text-white disabled:bg-gray800 disabled:text-white disabled:border-gray800 disabled:hover:border-gray800",
  selectedFill: "border-gray800 bg-gray800 text-white hover:text-white cursor-default",
  naked: "bg-transparent border-transparent text-gray800 hover:text-gray800",
} as const;

const roundedSizeStyle: Record<ButtonSize, string> = {
  xs: "text-sm leading-none font-semibold px-3.5 py-[7px]",
  sm: "text-sm leading-none font-semibold px-[18px] py-[13px]",
  md: "text-base leading-none font-bold px-5 py-[17px]",
  lg: "text-base leading-none font-bold px-6 py-[21px]",
  jb: "text-xl leading-none font-bold p-[25px]",
} as const;

const circleSizeStyle: Record<ButtonSize, string> = {
  xs: "h-8 w-8 text-sm",
  sm: "h-8 w-8 text-sm",
  md: "h-10 w-10 text-lg",
  lg: "h-10 w-10 text-lg",
  jb: "h-10 w-10 text-lg",
} as const;

const getCircleSize = (size: ButtonSize, shape: ButtonShape) =>
  shape === ButtonShape.CIRCLE ? circleSizeStyle[size] : roundedSizeStyle[size];

export const getButtonStyle = memoizee(
  (
    variant: ButtonVariant = ButtonVariant.FILL,
    size: ButtonSize = ButtonSize.MD,
    shape: ButtonShape = ButtonShape.ROUND,
    callerStyle: string,
  ): string =>
    [
      styles.focus,
      styles.base,
      styles.baseText,
      styles.baseInteraction,
      variantStyle[variant],
      getCircleSize(size, shape),
      shapeStyle[shape],
      callerStyle,
    ].join(" "),
  {
    normalizer: JSON.stringify,
  },
);

const statusStyle: Record<ButtonStatus, string> = {
  [ButtonStatus.IDLE]: `${variantStyle.fill} ${styles.baseInteraction}`,
  [ButtonStatus.LOADING]: "border-gray800 bg-gray800 text-white cursor-wait",
  [ButtonStatus.SUCCESS]: "border-green-300 bg-green-300 text-gray-1000 cursor-default",
};

export const getAsyncButtonStyle = memoizee(
  (status: ButtonStatus, size: ButtonSize = ButtonSize.MD, callerStyle: string): string =>
    [
      "gap-2", // to space loading and success icons from label
      styles.focus,
      styles.base,
      styles.baseText,
      statusStyle[status],
      roundedSizeStyle[size],
      shapeStyle[ButtonShape.ROUND],
      callerStyle,
    ].join(" "),
  {
    normalizer: JSON.stringify,
  },
);
