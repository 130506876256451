import React, {useCallback, useEffect, useRef, useState} from "react";

import Label from "../components/Label";
import {inputStyles} from "../utils/commonStyles";
import {randomId} from "../utils/randomId";
import {RegionData} from "./utils/getRegionData";

type RegionSelection = {
  region: string;
  regionData: RegionData[];
  handleRegionChange: (input: string) => void;
  label?: string;
  className?: string;
  ariaLabel?: string;
};

export const RegionSelection: React.FC<RegionSelection> = ({
  region,
  regionData,
  handleRegionChange,
  label,
  className,
  ariaLabel,
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const ref = useRef<HTMLSelectElement | null>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => handleRegionChange(e.target.value),
    [handleRegionChange],
  );

  const [inputId, setInputId] = useState<string>("");
  const [labelId, setLabelId] = useState<string>("");

  useEffect(() => {
    setInputId(randomId());
    setLabelId(randomId());
  }, []);

  const handleOnFocus = useCallback(() => setFocused(true), []);

  return (
    <div className="pos-r w-full">
      <div
        onClick={() => {
          ref.current?.focus();
        }}
        className={`${inputStyles.wrapper} ${borderStyles(focused)} ${className || ""} ${
          label ? "pt7" : ""
        } ${inputBgColor(focused)} cursor-text`}
      >
        <Label label={label} id={labelId} htmlFor={inputId} isLabelSmall={true} />
        <select
          id={inputId}
          ref={ref}
          value={region}
          aria-label={ariaLabel}
          aria-labelledby={labelId}
          className={`${inputStyles.input} h-6 ml-[-3px] ${label && "top-1"}`}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={() => setFocused(false)}
        >
          {regionData.map((region: RegionData) => (
            <option key={region.slug} value={region.slug}>
              {`${region.slug} (+${region.callingCode})`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const borderStyles = (focused: boolean) =>
  focused ? "border-blue-500 border-2 border-solid" : `border-2 border-solid border-gray-100`;

const inputBgColor = (focused: boolean) => (focused ? "bg-blue-100" : "bg-gray-100");
