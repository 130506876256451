import React from "react";

type ErrorMessageProps = {
  id?: string;
  msg?: string;
};

const ErrorMessage = ({id, msg}: ErrorMessageProps) => {
  if (!msg) return null;

  return (
    <p id={id} aria-live="assertive" className="text-xs mt-1 text-error-700 md:max-w-[12.5rem]">
      {msg}
    </p>
  );
};

export default React.memo(ErrorMessage);
