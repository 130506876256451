import {getSupportedRegionCodes} from "awesome-phonenumber";
import codes from "country-calling-code";

export type RegionData = {
  name: string;
  slug: string;
  callingCode: string;
};

export const getRegionData = (): RegionData[] => {
  const supportedRegions = getSupportedRegionCodes();

  const countryCodeToRegionCodeMap = supportedRegions
    .map(r => {
      const country = codes.find(c => c.isoCode2 === r);
      if (country)
        return {
          name: country.country,
          slug: country.isoCode2,
          callingCode: country.countryCodes[0],
        };
    })
    .flat()
    .compact()
    .sortBy(x => x.name);

  return countryCodeToRegionCodeMap;
};
