import Link, {LinkProps} from "next/link";
import React from "react";

type LinkOrButtonProps =
  | ({
      href: LinkProps["href"];
    } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">)
  | ({
      href: undefined | null;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>);

const LinkOrButton = React.memo(
  ({
    href,
    children,
    ...props
  }: LinkOrButtonProps & React.ButtonHTMLAttributes<HTMLAnchorElement>) =>
    href ? (
      <Link href={href} {...props}>
        <>{children}</>
      </Link>
    ) : (
      // @ts-expect-error TS2322: Type '{ children: ReactI18NextChild | Iterable<ReactI18NextChild>; about?: string | undefined; download?: any; id?: string | undefined; ... 268 more ...; value?: string | ... 2 more ... | undefined; } | { ...; }' is not assignable to type 'DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>'.
      <button {...props}>{children}</button>
    ),
);

LinkOrButton.displayName = "LinkOrButton";

export default LinkOrButton;
